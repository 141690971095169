<template>
  <div class="page-pricing">
    <!-- 头部 -->
    <div
      class="page-header"
      :style="{
        boxShadow:
          showBottom == true
            ? '5px 5px 13px #f2f2f2,-5px -5px 13px #ffffff'
            : 'none'
      }"
    >
      <el-row>
        <el-col :span="12">
          <div class="navbar-header">
            <router-link to="/">
              <img src="../assets/imgs/logoText.png" />
            </router-link>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="bug hidden-sm-and-up">
            <a @click="showMenu">
              <i class="menu iconfont icon-chanpinjieshaoye-gengduo" />
            </a>
          </div>
          <div class="header-right hidden-xs-only">
            <ul>
              <router-link to="/">
                <li @click="changeActive(1)">
                  <a :class="{ active: isActive == 1 }" href="#">产品</a>
                </li>
              </router-link>
              <!-- <router-link to="/case">
                <li @click="changeActive(2)">
                  <a :class="{ active: isActive == 2 }" href="#">案例</a>
                </li>
              </router-link> -->
              <router-link to="/pricing">
                <li @click="changeActive(3)">
                  <a :class="{ active: isActive == 3 }" href="#">咨询与购买</a>
                </li>
              </router-link>
              <!-- <li @click="toSuggestion">
                <span>意见与反馈</span>
              </li> -->
              <!-- <li @click="toOldVersion">
                <span>前往旧版</span>
              </li> -->
              <li>
                <div id="user" v-if="isLogin">
                  <router-link to="/home">
                    <span class="into">
                      进入系统
                      <img src="../assets/imgs/you.png" alt="" />
                    </span>
                  </router-link>
                  <img class="person" src="../assets/imgs/people.png" alt="" />
                  <a id="userName">{{ userName }}</a>
                </div>
                <div v-else>
                  <!-- <router-link to="/register">
                    <span class="register">注册</span>
                  </router-link> -->
                  <router-link to="/wait" target="_blank">
                    <el-button type="primary" class="login">登录</el-button>
                  </router-link>
                </div>
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="menuBox" v-show="menuBox">
      <ul>
        <router-link to="/">
          <li @click="changeActive(1)">
            <a :class="{ active: isActive == 1 }" href="#">产品</a>
          </li>
        </router-link>
        <!-- <router-link to="/case">
          <li @click="changeActive(2)">
            <a :class="{ active: isActive == 2 }" href="#">案例</a>
          </li>
        </router-link> -->
        <router-link to="/pricing">
          <li @click="changeActive(3)">
            <a :class="{ active: isActive == 3 }" href="#">咨询与购买</a>
          </li>
        </router-link>
      </ul>
    </div>

    <!-- 主体区域 -->
    <div class="nav">
      <div class="section-plan">
        <div class="section-header sectionHeader">
          <h1 class="title">按需付费，一切为您！</h1>
          <p class="desc">
            企业全面智能分析报告下载、支持团队功能，为您提供方便、快捷的企业查询与评价服务
          </p>
        </div>
        <!-- 版本服务表-->
        <table v-if="!isToMore" class="version-header">
          <tr class="th">
            <td>
              <span class="title">服务说明</span>
              <br />
              <span class="desc">支付后可开发票</span>
            </td>
            <td>
              <span class="title">基础版</span>
              <br />
              <el-button class="button1" type="info" plain @click="goWait">
                立即购买
              </el-button>
            </td>
            <td>
              <el-tag class="tag" type="warning" size="small">推荐</el-tag>
              <span class="title">高级版</span>
              <el-tooltip placement="top-start" effect="light" :offset="3">
                <template #content>
                  <div style="font-family: SourceHanSansSC-regular">
                    支持团队功能，可管理分配多账号
                  </div>
                </template>
                <i class="iconfont icon-tuandui" />
              </el-tooltip>
              <br />
              <el-button class="button2" type="warning" plain @click="goWait">
                立即购买
              </el-button>
            </td>
            <td>
              <span class="title">旗舰版</span>
              <el-tooltip placement="top-start" effect="light" :offset="3">
                <template #content>
                  <div style="font-family: SourceHanSansSC-regular">
                    支持团队功能，可管理分配多账号
                  </div>
                </template>
                <i class="iconfont icon-tuandui" />
              </el-tooltip>
              <br />
              <el-button class="button3" type="primary" plain @click="goWait">
                立即购买
              </el-button>
            </td>
          </tr>
        </table>
        <table v-if="!isToMore" class="version">
          <tr class="tr-title" style="background-color: #fff">
            <td class="td-bold">深度信息查看/字段导出/报告下载</td>
            <td>1000家</td>
            <td>1000家</td>
            <td>1000家</td>
          </tr>
          <tr class="tr-title">
            <td class="td-bold">基础信息</td>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td>注册变更历史</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>股东信息</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>高管信息</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>法人对外投资与任职</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>企业家族树</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>竞争对手</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>附近的同行</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>附近的企业</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>核心产品</td>
            <td></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>商业信号</td>
            <td></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>新闻舆情</td>
            <td></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr class="tr-title">
            <td class="td-bold">综合评价</td>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td>影响指数</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>创新指数</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>购买指数</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>销售指数</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>招聘指数</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>内部风险指数</td>
            <td></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>外部风险指数</td>
            <td></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>信用质量</td>
            <td></td>
            <td></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>生存能力</td>
            <td></td>
            <td></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>经营能力</td>
            <td></td>
            <td></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr class="tr-title" style="background-color: #fff">
            <td class="td-bold">财务指标</td>
            <td></td>
            <td>
              <i class="iconfont icon-zixunyugoumai-shi" />
              <el-tooltip placement="top-start" effect="light" :offset="-5">
                <template #content>
                  <div style="font-family: SourceHanSansSC-regular">
                    上市企业提供精准的财务指
                    <br />
                    标数据，非上市企业提供实
                    <br />
                    际的财务指标区间范围。
                  </div>
                </template>
                <i class="iconfont icon-wenhao" />
              </el-tooltip>
            </td>
            <td>
              <i class="iconfont icon-zixunyugoumai-shi" />
              <el-tooltip placement="top-start" effect="light" :offset="-5">
                <template #content>
                  <div style="font-family: SourceHanSansSC-regular">
                    上市企业提供精准的财务指
                    <br />
                    标数据，非上市企业提供实
                    <br />
                    际的财务指标区间范围和财
                    <br />
                    务指标的估计值。
                  </div>
                </template>
                <i class="iconfont icon-wenhao" />
              </el-tooltip>
            </td>
          </tr>
          <tr class="tr-title">
            <td class="td-bold">经营信息</td>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td>招标投标</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>人才招聘</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>行政许可</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>专利</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>著作权</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>商标</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>投融资</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>营销号</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>网站</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>APP</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr class="tr-title" style="background-color: #fff">
            <td class="td-bold">信用风险</td>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td>行政处罚</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>司法文书</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>股权变动</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
          <tr>
            <td>动产抵押</td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
            <td><i class="iconfont icon-zixunyugoumai-shi" /></td>
          </tr>
        </table>
      </div>
    </div>

    <!-- 底部 -->
    <div class="footer">
      <!-- <div class="footer-middle">
        <div class="bottom-menu">
          <span>关于我们</span>
          <span>服务协议</span>
          <span>隐私政策</span>
        </div>
      </div> -->
      <div class="footer-bottom">
        <p>
          Copyright &copy;
          <a
            href="http://www.monetware.com"
            target="_blank"
            title="数据研究技术服务商"
          >
            2023 上海萌泰数据科技股份有限公司
          </a>
          Inc. All rights reserved. &ensp;&ensp;
          <a
            href="http://beian.miit.gov.cn"
            target="_blank"
            :style="
              ($route.name == 'login' || $route.name == 'register') &&
              'color: #000000;'
            "
          >
            沪ICP备09099644号-19
          </a>
        </p>
      </div>
    </div>
    <el-dialog title="跳转提示" v-model="dialogVisible" width="30%">
      <span>
        2022年1月27日及此后的所有旧版数据都将不会和新版进行同步。是否进行跳转？
      </span>
      <!-- <span slot="footer" class="dialog-footer"> -->
      <template v-slot:footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmToOldVersion">
            确 定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
// import VueCookies from "vue-cookies";
// import { httpPost } from "../api/httpService";
export default {
  data() {
    return {
      centerDialogVisible: false, //立即咨询弹框
      isToMore: false, //更多套餐
      isActive: 3,
      isLogin: false, // 用户是否登录
      showBottom: false,
      userName: "",
      menuBox: false,
      dialogVisible: false
    };
  },
  created() {
    // console.log("pricing", this);
    /*判断用户是否登陆*/
    // if (VueCookies.isKey("tokenWeb")) {
    //   let userInfo = VueCookies.get("userInfo");
    //   this.userName = userInfo.name;
    //   httpPost("/auth/role", null).then((res) => {
    //     if (res && res.code === 0) {
    //       userInfo.nlpPermission = res.data.role;
    //       userInfo.ifLoginNlp = true;
    //       VueCookies.set("userInfo", JSON.stringify(userInfo));
    //       VueCookies.set(
    //         "userInfo",
    //         JSON.stringify(userInfo),
    //         2 * 60 * 60,
    //         "/",
    //         "ringdata.com",
    //         false
    //       );
    //     }
    //   });
    //   this.isLogin = true;
    // } else {
    //   this.isLogin = false;
    // }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听（绑定）滚轮滚动事件
    this.getPermission();
  },
  methods: {
    toSuggestion() {
      window.open(
        "https://www.ringdata.com/feedback?productCategory=2&productType=5"
      );
    },
    /**
     * 更多套餐
     */
    toMore() {
      this.isToMore = true;
    },
    /**
     * 返回
     */
    toBack() {
      this.isToMore = false;
    },

    goWait() {
      const resolved = this.$router.resolve({
        path: "/wait"
      });
      window.open(resolved.href, "_blank");
    },

    toOldVersion() {
      // console.log("???");
      this.dialogVisible = true;
      // window.open("https://wx.ringdata.com")
    },
    confirmToOldVersion() {
      this.dialogVisible = false;
      window.open("https://wx.ringdata.com/old/");
    },
    /*获取权益*/
    getPermission() {
      // httpPost("/auth/getPermission").then((result) => {
      //   if (result.code == 0) {
      //     let tempList = result.data;
      //     for (let i = 0; i < tempList.length; i++) {
      //       if (tempList[i].libraryNum >= 10000) {
      //         tempList[i].libraryNum =
      //           tempList[i].libraryNum / 10000.0 + "万条";
      //       } else {
      //         tempList[i].libraryNum = tempList[i].libraryNum + "条";
      //       }
      //       if (tempList[i].maxTextNum >= 10000) {
      //         tempList[i].maxTextNum =
      //           tempList[i].maxTextNum / 10000.0 + "万条";
      //       } else {
      //         tempList[i].maxTextNum = tempList[i].maxTextNum + "条";
      //       }
      //     }
      //     this.resultList = result.data;
      //   }
      // });
    },

    /*点击立即体验事件*/
    onRegister() {
      // if (VueCookies.isKey("tokenWeb")) {
      //   this.$router.push({
      //     path: "/textLibrary"
      //   });
      // } else {
      //   this.$router.push({
      //     path: "/login"
      //   });
      // }
    },

    /*点击立即购买事件*/
    onCreateOrder(id) {
      if (this.isLogin) {
        window.open(`https://ringdata.com/createOrder?st=${id}`);
      } else {
        this.$message.info("您还未登录，请登录后购买使用");
      }
    },

    // 切换导航
    changeActive(current) {
      if (current != 3) {
        this.destroyed();
      }
      this.isActive = current;
    },
    // 监听滚轮事件
    handleScroll: function () {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll > 0) {
        this.showBottom = true;
      } else {
        this.showBottom = false;
      }
    },

    destroyed: function () {
      window.removeEventListener("scroll", this.handleScroll); //  离开页面清除（移除）滚轮滚动事件
    },

    // 展开菜单
    showMenu() {
      this.menuBox = !this.menuBox;
    }
  }
};
</script>

<style lang="scss" scoped>
.page-pricing {
  // 版本价格表格start
  .version-header {
    width: 1200px;
    text-align: center;
    position: sticky;
    top: 60px;
    z-index: 1000;
    background-color: #fff;
    line-height: 25px;
    .th {
      text-align: center !important;
      td {
        padding: 18px 0px;
        border: 1px solid #efefef;
        position: relative;
        .tag {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 10px;
          background-color: rgba(244, 143, 66, 1);
          border: 0px;
          color: rgba(255, 255, 255, 1);
          padding: 5px;
          font-family: SourceHanSansSC-regular;
        }
      }
      .title {
        color: rgba(16, 16, 16, 1);
        font-size: 16px;
        text-align: left;
        font-family: SourceHanSansSC-regular;
        font-weight: 500;
      }
      .desc {
        color: rgba(154, 154, 154, 1);
        font-size: 12px;
        text-align: left;
        font-family: SourceHanSansSC-regular;
      }
      .iconfont {
        margin-left: 10px;
        color: rgba(26, 120, 255, 1);
        position: absolute;
      }
      .button1 {
        font-size: 12px;
        height: 25px;
        background-color: rgba(190, 190, 190, 0.1);
        border-color: rgba(190, 190, 190, 0.1);
        color: rgba(154, 154, 154, 1);
        font-family: SourceHanSansSC-regular;
      }
      .button2 {
        font-size: 12px;
        height: 25px;
        background-color: rgba(244, 143, 66, 0.1);
        border-color: rgba(244, 143, 66, 0.1);
        color: rgba(244, 143, 66, 1);
        font-family: SourceHanSansSC-regular;
      }
      .button3 {
        font-size: 12px;
        height: 25px;
        background-color: rgba(148, 128, 228, 0.1);
        border-color: rgba(148, 128, 228, 0.1);
        color: rgba(148, 128, 228, 1);
        font-family: SourceHanSansSC-regular;
      }
    }
    tr td:nth-child(1) {
      width: 350px;
    }
  }
  .version {
    width: 1200px;
    height: 300px;
    text-align: center;
    line-height: 40px;
    border: 1px solid #efefef;
    border-top: 0px;
    border-collapse: collapse; /* 合并单元格 */
    // margin-top: 80px;
    tr {
      .iconfont {
        color: #188b99;
        display: inline-block;
        vertical-align: middle;
      }
      .icon-wenhao {
        position: absolute;
        color: #000;
        font-size: 12px;
        font-weight: bold;
        margin-left: 10px;
        vertical-align: middle;
      }
    }
    .tr-title {
      border: 1px solid #efefef;
      .td-bold {
        font-weight: bold;
        color: #000;
      }
    }
    tr:nth-child(1) {
      border-top: 0px;
    }
    tr:nth-child(odd) {
      background-color: rgba(24, 139, 153, 0.05);
    }
    tr td:nth-child(1) {
      width: 350px;
    }
  }
  .mr {
    margin-right: 0 !important;
  }
  .ml {
    margin-left: 40px !important;
  }
  #user {
    line-height: 0;
    .person {
      display: inline-block;
      width: 18px;
      vertical-align: middle;
      margin-bottom: 4px;
      margin-right: 3px;
    }
  }
  .bug {
    cursor: pointer;
    width: 35px;
    height: 60px;
    float: right;
    padding-right: 10px;
    display: inline-block;
    -webkit-tap-highlight-color: transparent;
    a {
      cursor: pointer;
      width: 35px;
      height: 60px;
      display: inline-block;
      -webkit-tap-highlight-color: transparent;
    }
  }
  .menu {
    font-size: 28px;
    // color: #666;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    line-height: 40px;
    position: relative;
    top: 10px;
  }
  .menuBox {
    z-index: 1000;
    position: fixed;
    top: 60px;
    left: 0;
    transform: translateX(320%);
    width: 22%;
    text-align: left;
    line-height: 50px;
    background-color: #fff;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    li {
      cursor: pointer;
      z-index: 1000;
      a {
        margin-left: 10px;
        &:hover {
          color: #188b99;
        }
        &.active {
          color: #188b99;
        }
      }
    }
    .menu-setting {
      border-top: 1px solid #fff;
      padding-top: 15px;
      .register {
        width: 70px;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        margin-right: 40px;
      }
    }
  }
  .into {
    margin-left: 30px;
    margin-right: 10px;
    padding: 6px 8px;
    border-radius: 4px;
    font-size: 13px;
    img {
      display: inline-block;
      width: 20px;
      vertical-align: middle;
      margin-bottom: 4px;
    }
  }
  a,
  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
    color: #444;
  }
  button,
  button:focus {
    outline: none;
  }
  ul,
  ol {
    list-style: none;
    padding: 0;
  }
  .icon-banner {
    max-width: 1200px;
    margin: 0 auto;
  }
  .container {
    max-width: 1200px;
  }
  // 头部
  .page-header {
    position: fixed;
    left: 0;
    top: 0;
    height: 60px;
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    z-index: 10;
    .navbar-header {
      width: 20%;
      height: 60px;
      line-height: 60px;
      float: left;
      padding: 0px 10px 0px;
      img {
        display: inline-block;
        width: 98px;
        height: 35px;
        margin-left: 65px;
        vertical-align: middle;
      }
    }
    .header-right {
      float: right;
      text-align: right;
      ul {
        width: 100%;
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        margin-right: 50px;
        li {
          margin-right: 20px;
          float: left;
          cursor: pointer;
          a {
            font-weight: bold;
            &.active {
              color: #188b99;
            }
          }
          .register {
            font-size: 15px;
            font-weight: 500;
            color: #000;
            margin-left: 20px;
          }
          i {
            color: #606266;
          }
          #userImg {
            display: inline-block;
            width: 13px;
            height: 13px;
            margin-right: 5px;
            margin-left: 10px;
          }
          #userName {
            margin-left: 2px;
            display: inline-block;
            padding: 0;
            line-height: 60px;
            font-size: 14px;
            color: #777;
          }
          #userName:hover {
            color: #777;
          }
          .login {
            padding: 8px 13px;
            // margin-left: 30px;
            margin-bottom: 3px;
            background-color: rgba(24, 139, 153, 1);
            border-color: rgba(24, 139, 153, 1);
            color: rgba(255, 255, 255, 1);
            font-size: 12px;
            height: 25px;
            font-family: Roboto;
          }
        }
      }
    }
  }
  // 主体
  .nav {
    padding: 120px 0px 10px;
    max-width: 1200px;
    margin: 0 auto;
    font-weight: 300 !important;
    color: #666;
    .section-plan {
      margin-left: 8px;
      margin-right: 8px;
      h1 {
        margin-bottom: 40px;
      }
      .section-header {
        // position: fixed;
        // top: 60px;
        // left: 0;
        width: 100%;
        height: 120px;
        overflow: hidden;
        background-color: #fff;
        z-index: 10;
        // padding-top: 60px;
        padding-bottom: 10px;
        h1.title {
          background: #fff;
          text-align: center;
          color: #000;
          font-size: 34px;
          font-weight: 500;
          margin-top: 0;
          margin-bottom: 30px;
          font-family: SourceHanSansSC-regular;
        }
        p.desc {
          text-align: center;
          font-weight: 300;
          padding: 10px;
          color: rgba(108, 108, 108, 1);
          font-size: 14px;
          font-family: SourceHanSansSC-regular;
        }
      }
    }
  }
  // 底部
  .footer {
    padding: 30px 10px;
    text-align: center;
    background: #fff;
    .footer-middle {
      color: #a0a0a0;
      padding: 10px 0px;
      font-size: 12px;
      span {
        margin: 0px 5px;
        cursor: pointer;
      }
    }
    .footer-bottom {
      font-size: 12px;
      p {
        color: #a0a0a0;
      }
      a {
        color: #337ab7;
      }
    }
  }
}

//手机自适应
@media screen and (max-width: 767px) {
  .page-pricing {
    // 版本价格表格start
    .version-header {
      width: 500px;
      text-align: center;
      position: sticky;
      top: 60px;
      z-index: 1000;
      background-color: #fff;
      line-height: 25px;
      .th {
        text-align: center !important;
        td {
          padding: 18px 0px;
          border: 1px solid #efefef;
          position: relative;
          .tag {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 10px;
            background-color: rgba(244, 143, 66, 1);
            border: 0px;
            color: rgba(255, 255, 255, 1);
            padding: 5px;
            font-family: SourceHanSansSC-regular;
          }
        }
        .title {
          color: rgba(16, 16, 16, 1);
          font-size: 14px;
          text-align: left;
          font-family: SourceHanSansSC-regular;
          font-weight: 500;
        }
        .desc {
          color: rgba(154, 154, 154, 1);
          font-size: 12px;
          text-align: left;
          font-family: SourceHanSansSC-regular;
        }
        .iconfont {
          margin-left: 10px;
          color: rgba(26, 120, 255, 1);
          position: absolute;
        }
        .button1 {
          font-size: 12px;
          font-family: SourceHanSansSC-regular;

          height: 25px;
          background-color: rgba(190, 190, 190, 0.1);
          border-color: rgba(190, 190, 190, 0.1);
          color: rgba(154, 154, 154, 1);
        }
        .button2 {
          font-size: 12px;
          font-family: SourceHanSansSC-regular;

          height: 25px;
          background-color: rgba(244, 143, 66, 0.1);
          border-color: rgba(244, 143, 66, 0.1);
          color: rgba(244, 143, 66, 1);
        }
        .button3 {
          font-size: 12px;
          font-family: SourceHanSansSC-regular;

          height: 25px;
          background-color: rgba(148, 128, 228, 0.1);
          border-color: rgba(148, 128, 228, 0.1);
          color: rgba(148, 128, 228, 1);
        }
      }
      tr td:nth-child(1) {
        width: 120px;
      }
    }
    .version {
      width: 500px;
      height: 300px;
      text-align: center;
      line-height: 40px;
      border: 1px solid #efefef;
      border-top: 0px;
      border-collapse: collapse; /* 合并单元格 */
      tr {
        .iconfont {
          color: #188b99;
          display: inline-block;
          vertical-align: middle;
        }
        .icon-wenhao {
          position: absolute;
          color: #000;
          font-size: 12px;
          font-weight: bold;
          margin-left: 10px;
          vertical-align: middle;
        }
      }
      .tr-title {
        border: 1px solid #efefef;
        .td-bold {
          font-weight: bold;
          color: #000;
        }
      }
      tr:nth-child(1) {
        border-top: 0px;
      }
      tr:nth-child(odd) {
        background-color: rgba(24, 139, 153, 0.05);
      }
      tr td:nth-child(1) {
        width: 120px;
      }
    }
    .mr {
      margin-right: 0 !important;
    }
    .ml {
      margin-left: 40px !important;
    }
    #user {
      line-height: 0;
      .person {
        display: inline-block;
        width: 18px;
        vertical-align: middle;
        margin-bottom: 4px;
        margin-right: 3px;
      }
    }
    .bug {
      cursor: pointer;
      width: 35px;
      height: 60px;
      float: right;
      padding-right: 10px;
      display: inline-block;
      -webkit-tap-highlight-color: transparent;
      a {
        cursor: pointer;
        width: 35px;
        height: 60px;
        display: inline-block;
        -webkit-tap-highlight-color: transparent;
      }
    }
    .menu {
      font-size: 28px;
      // color: #666;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      line-height: 40px;
      position: relative;
      top: 10px;
    }
    .menuBox {
      z-index: 1000;
      position: fixed;
      top: 60px;
      left: 0;
      transform: translateX(320%);
      width: 22%;
      text-align: left;
      line-height: 50px;
      background-color: #fff;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      li {
        cursor: pointer;
        z-index: 1000;
        a {
          margin-left: 10px;
          &:hover {
            color: #188b99;
          }
          &.active {
            color: #188b99;
          }
        }
      }
      .menu-setting {
        border-top: 1px solid #fff;
        padding-top: 15px;
        .register {
          width: 70px;
          height: 50px;
          line-height: 50px;
          font-size: 16px;
          margin-right: 40px;
        }
      }
    }
    .into {
      margin-left: 30px;
      margin-right: 10px;
      padding: 6px 8px;
      border-radius: 4px;
      font-size: 13px;
      img {
        display: inline-block;
        width: 20px;
        vertical-align: middle;
        margin-bottom: 4px;
      }
    }
    a,
    a:hover,
    a:active,
    a:focus {
      text-decoration: none;
      color: #444;
    }
    button,
    button:focus {
      outline: none;
    }
    ul,
    ol {
      list-style: none;
      padding: 0;
    }
    .icon-banner {
      max-width: 1200px;
      margin: 0 auto;
    }
    .container {
      max-width: 1200px;
    }
    // 头部
    .page-header {
      position: fixed;
      left: 0;
      top: 0;
      height: 60px;
      width: 100%;
      overflow: hidden;
      background-color: #fff;
      z-index: 10;
      .navbar-header {
        width: 20%;
        height: 60px;
        line-height: 60px;
        float: left;
        padding: 0px 10px 0px;
        img {
          display: inline-block;
          width: 70px;
          height: 25px;
          margin-left: 10px;
          vertical-align: middle;
        }
      }
      .header-right {
        float: right;
        text-align: right;
        ul {
          width: 100%;
          height: 60px;
          line-height: 60px;
          overflow: hidden;
          li {
            margin-right: 20px;
            float: left;
            cursor: pointer;
            a {
              font-weight: bold;
              &.active {
                color: #188b99;
              }
            }
            .register {
              font-size: 15px;
              font-weight: 500;
              color: #000;
              margin-left: 20px;
            }
            i {
              color: #606266;
            }
            #userImg {
              display: inline-block;
              width: 13px;
              height: 13px;
              margin-right: 5px;
              margin-left: 10px;
            }
            #userName {
              margin-left: 2px;
              display: inline-block;
              padding: 0;
              line-height: 60px;
              font-size: 14px;
              color: #777;
            }
            #userName:hover {
              color: #777;
            }
            .login {
              padding: 8px 13px;
              // margin-left: 30px;
              margin-bottom: 3px;
              background-color: rgba(24, 139, 153, 1);
              border-color: rgba(24, 139, 153, 1);
              color: rgba(255, 255, 255, 1);
              font-size: 12px;
              height: 25px;
              font-family: Roboto;
            }
          }
        }
      }
    }
    // 主体
    .nav {
      padding: 110px 0px 10px;
      max-width: 1200px;
      margin: 0 auto;
      font-weight: 300 !important;
      color: #666;
      .section-plan {
        margin-left: 8px;
        margin-right: 8px;
        h1 {
          margin-bottom: 40px;
        }
        .section-header {
          // position: fixed;
          // margin-top: 110px;
          margin-bottom: 10px;
          h1.title {
            background: #fff;
            text-align: center;
            color: #000;
            font-size: 25px;
            font-weight: 500;
            margin-top: 0;
            margin-bottom: 30px;
            font-family: SourceHanSansSC-regular;
          }
          p.desc {
            text-align: center;
            font-weight: 300;
            padding: 10px;
            color: rgba(108, 108, 108, 1);
            font-size: 14px;
            font-family: SourceHanSansSC-regular;
          }
        }
      }
    }
    // 底部
    .footer {
      padding: 30px 10px;
      text-align: center;
      background: #fff;
      .footer-middle {
        color: #a0a0a0;
        padding: 10px 0px;
        font-size: 12px;
        span {
          margin: 0px 5px;
          cursor: pointer;
        }
      }
      .footer-bottom {
        font-size: 12px;
        p {
          color: #a0a0a0;
        }
        a {
          color: #337ab7;
        }
      }
    }
  }
}
</style>
